import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { GalleryImages } from 'app/components/GalleryImages'
import { Hero } from 'app/components/Hero'
import { ImagesParagraph } from 'app/components/ImagesParagraph'
import { Info } from 'app/components/Info'
import { Intro } from 'app/components/Intro'
import { MosaicParagraph } from 'app/components/MosaicParagraph'
import { Review } from 'app/components/Review'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { ZigZagVisual } from 'app/components/ZigZagVisual'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Props as SidebarProps, Sidebar } from 'app/containers/Sidebar'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  sidebarProps?: SidebarProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function BeachClubPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.sidebarProps ? (
        <Sidebar
          languageCode={context.languageCode}
          {...context.sidebarProps}
        />
      ) : null}
      <GoogleAnalytics />
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.imagesParagraphProps ? (
        <ImagesParagraph variant="light" {...context.imagesParagraphProps} />
      ) : null}
      {context.zigZagVisualProps ? (
        <ZigZagVisual {...context.zigZagVisualProps} />
      ) : null}
      {context.galleryImagesProps ? (
        <GalleryImages variant="light" {...context.galleryImagesProps} />
      ) : null}
      {context.zigZagVisualSecondProps ? (
        <ZigZagVisual {...context.zigZagVisualSecondProps} />
      ) : null}
      {context.mosaicParagraphProps ? (
        <MosaicParagraph {...context.mosaicParagraphProps} />
      ) : null}
      {context.infoProps ? (
        <Info variant="light" {...context.infoProps} />
      ) : null}
      {context.reviewProps ? <Review {...context.reviewProps} /> : null}
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
