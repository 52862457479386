import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Fade from 'react-reveal/Fade'

export interface Props {
  description?: string
  images?: string[]
  title?: string
}

export const MosaicParagraph = memo(function MosaicParagraph({
  description,
  images,
  title,
}: Props) {
  if (!title || !description) {
    return null
  }

  return (
    <Container dial={4} row tag="section" wrap>
      <Fade bottom distance="3.75rem">
        <LeftSide>
          {title ? <Title>{title}</Title> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
        </LeftSide>
        <RightSide>
          {images && images.length > 0 ? (
            <LazyLoadComponent>
              <Images row wrap>
                {uniq(images)
                  .slice(0, 3)
                  .map((item, index) => (
                    <Image
                      key={index}
                      style={{ backgroundImage: `url(${item})` }}
                    />
                  ))}
              </Images>
            </LazyLoadComponent>
          ) : null}
        </RightSide>
      </Fade>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  overflow: hidden;
  padding: 7.5rem 10vw 12.125rem;
  position: relative;
  > div {
    width: 50%;
  }

  @media (max-width: 1199px) {
    padding: 3.75rem 8.454vw 10rem;
  }

  @media (max-width: 1023px) {
    > div {
      width: 100%;
      &:first-of-type {
        order: 2;
      }
    }
  }
`

const LeftSide = styled.div`
  padding-right: 7.431vw;

  @media (max-width: 1023px) {
    margin-top: 3.25rem;
    padding-right: 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 5.25rem;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.625rem;
  margin-top: 3rem;

  @media (max-width: 1023px) {
    margin-top: 1.5rem;
  }
`

const RightSide = styled.div``

const Images = styled(FlexBox)``

const Image = styled.div`
  width: 33.333%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 70%;
  transform: translateY(3rem);
  &:nth-of-type(2) {
    transform: translateY(0);
  }

  @media (max-width: 1023px) {
    transform: translateY(1.25rem);
  }
`
